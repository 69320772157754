
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <p>
          Remember eDDie!
        </p>

      </header>
    </div>
  );
}

export default App;
